import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from './../../../core/api/api.service';
import { isPlatformBrowser } from '@angular/common';
import { NotifierService } from './../../../ui/notifier/notifier.service';
import { PaymentInterface } from '../../../core/interface/payment.interface';

@Component({
	selector: 'app-profile-settings',
	templateUrl: './profile-settings.component.html',
	styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService
	) {}

	public payments: PaymentInterface[];

	// Переменные под форму
	public paymentData: PaymentInterface;
	public paymentContacts = '';

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {

			// Получаем все платёжные систему
			this.getPyaments();
		}
	}

	private async getPyaments() {

		try {

			this.payments = await this.apiService.call('payment.get').toPromise();
		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public async updatePayments() {

		try {

			await this.apiService.call('account.update.payment', {
				id: (this.paymentData?.id || ''),
				contact: encodeURIComponent(this.paymentContacts)
			}).toPromise();

			this.notifierService.update('Данные успешно обновлены', 'success', 5000);

			this.paymentContacts = '';
			this.paymentData = undefined;
		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
