import { Injectable, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { ProjectUrl } from 'src/app/app-export.constant';
import { NotifierService } from 'src/app/ui/notifier/notifier.service';
import { AuthorizationService } from '../services/authorization.service';
import { AuthorizationLoginInterface } from '../interfaces/authorization-login.interface';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class AuthorizationVKGuard implements CanActivate {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private router: Router,
		private apiService: ApiService,
		private notifierService: NotifierService,
		private authorizationService: AuthorizationService
	) {}

	canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean | Promise<boolean> {

		if (isPlatformServer(this.platformId)) {
			return true;
		}

		const codeVK = activatedRouteSnapshot.queryParams.code;

		if (!codeVK) {
			return true;
		}

		return new Promise(async(resolve) => {

			try {

				// Запрос к API
				const result: AuthorizationLoginInterface = await this.apiService.call('authorization.sign-in', {
					code: codeVK,
					redirect_uri: ProjectUrl + 'login'
				}).toPromise();

				this.authorizationService.login(result, result.clientAuthCookie);

				this.router.navigate(['/']);

			} catch(error) {

				this.router.navigate(['/', 'login']);
				this.notifierService.update(error.message, 'error', 5000);
			} finally {
				resolve(false);
			}
		});
	}
}
