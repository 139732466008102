import { Injectable } from '@angular/core';
import { ApiInterface, ApiErrorInterface } from './api.interface';
import { HttpClient } from '@angular/common/http';
import { APIProject } from './../../app-export.constant';
import { switchMap } from 'rxjs/operators';
import { of, throwError, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ApiService {

    constructor(
        private httpClient: HttpClient,
        private cookieService: CookieService
    ) {}

    call(method: string, params: any = {}): Observable<ApiErrorInterface | any> {

        // Добавляем Auth Cookie (a)
        params.a = this.cookieService.get('a');

        return this.httpClient.get<ApiInterface>(APIProject + method, {
            params: params
        }).pipe(
            switchMap((response: ApiInterface) => {

                if (response.error) {
					return throwError(response.error);
				}

				return of(response.response);
            })
        );
    }
}