<div class="fixed-size">
	<div class="footer-wrapper">
		<div class="footer-section">
			<a class="footer-logotype" [routerLink]="['/']"></a>
			<div class="footer-text">AMAZING MEDIA — Это команда блогеров, которые делают крутой контент и зарабатывают на этом</div>
		</div>
		<div class="footer-section">
			<div class="footer-title">Навигация</div>
			<div class="footer-navigation">
				<a class="footer-navigation-item" [routerLink]="['/']">Главная</a>
				<a class="footer-navigation-item" [routerLink]="['/', 'payout']">Заявка на выплату</a>
				<a class="footer-navigation-item" [routerLink]="['/', 'profile', 'payouts']">Мои заявки</a>
			</div>
		</div>
		<div class="footer-section">
			<div class="footer-title">Наши проекты</div>
			<div class="footer-navigation">
				<a class="footer-navigation-item" href="https://amazing-rp.ru/" target="_blank">Amazing RolePlay</a>
				<a class="footer-navigation-item" href="https://amazing-craft.ru/" target="_blank">Amazing Craft</a>
				<a class="footer-navigation-item" target="_blank">GTA V (В разработке)</a>
			</div>
		</div>
	</div>
</div>
