import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { NavigationStyleService } from './navigation-style.service';

@NgModule({
	declarations: [
		NavigationComponent
	],
	exports: [
		NavigationComponent
	],
	imports: [
		CommonModule,
		RouterModule
	],
	providers: [
		NavigationStyleService
	]
})
export class NavigationModule {}
