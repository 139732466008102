import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyTextDirective } from './directives/copy-text.directive';

@NgModule({
    declarations: [
        CopyTextDirective
    ],
    exports: [
        CopyTextDirective
    ],
    imports: [
        CommonModule
    ]
})
export class DirectiveModule { }
