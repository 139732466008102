import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from './../../../ui/notifier/notifier.service';
import { AuthorizationService } from './../../../authorization/shared/services/authorization.service';
import { AdminPayoutsInterface } from '../../shared/interfaces/admin-payouts.interfaces';

@Component({
	selector: 'app-admin-payouts',
	templateUrl: './admin-payouts.component.html',
	styleUrls: ['./admin-payouts.component.scss']
})
export class AdminPayoutsComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService,
		public authorizationService: AuthorizationService
	) {}

	public payouts: AdminPayoutsInterface[];

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			
			this.getPayouts();
		}
	}

	private async getPayouts() {

		try {

			this.payouts = await this.apiService.call('payouts.get.users').toPromise();
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
