import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizationGuard } from './authorization.guard';
import { LoginComponent } from './pages/login/login.component';
import { AuthorizationService } from './shared/services/authorization.service';
import { AuthorizationVKGuard } from './shared/guards/authorization-vk.guard';

@NgModule({
    declarations: [
        LoginComponent
    ],
    exports: [
        LoginComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
        AuthorizationGuard,
        AuthorizationService,
        AuthorizationVKGuard
    ]
})
export class AuthorizationModule { }
