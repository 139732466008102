import { Component, HostBinding } from '@angular/core';
import { NavigationStyleService } from '../navigation-style.service';
import { AuthorizationService } from 'src/app/authorization/shared/services/authorization.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

	constructor(
		private navigationStyleService: NavigationStyleService,
		public authorizationService: AuthorizationService
	) {}

	@HostBinding('class') get ClassName() {
		return this.navigationStyleService.navigationStyle;
	}
}
