import { Component, ViewChild, HostBinding, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ModalityDirective } from '../shared/directives/modality.directive';
import { ModalityService } from '../shared/services/modality.service';
import { ModalityOpenInterface, ModalityCloseInterface } from '../shared/interfaces/modality.interfaces';
import { ScrollingService } from './../../core/scrolling/scrolling.service';

@Component({
	selector: 'app-modality',
	templateUrl: './modality.component.html',
	styleUrls: ['./modality.component.scss']
})
export class ModalityComponent implements OnInit {

	@ViewChild(ModalityDirective, { static: true }) modalityComponent: ModalityDirective;

	@HostBinding('class.opened') get className() {
		return this.modalityService.opened;
	}

	constructor(
		private modalityService: ModalityService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private scroll: ScrollingService
	) {}

	ngOnInit() {

		// Инициализируем
		this.init();
	}

	private init() {

		/* Так как компонент загружается позже, то, возможно, уже пришёл вызов
		 * на открытие первого модального окна. Проверим это
		*/
		if (this.modalityService.component) {
			this.createComponent(this.modalityService.component);
		}

		// Слушаем открытие модальных окон
		this.modalityService.openEvent.subscribe((componentData: ModalityOpenInterface) => {
			this.createComponent(componentData);
		});

		// Слушаем закрытие модальных окон
		this.modalityService.closeEvent.subscribe((componentData: ModalityCloseInterface) => {
			this.destoryComponent(componentData);
		});
	}

	private createComponent(componentData: ModalityOpenInterface) {

		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentData.component);

		// Если компонент уже открыт, закрываем
		if(this.modalityService.opened) {

			this.modalityComponent.viewContainerRef.clear();
			this.modalityService.closeEvent.emit();
		}

		
		this.modalityService.opened = true;
		this.scroll.disableScroll();

		const componentRef = this.modalityComponent.viewContainerRef.createComponent(componentFactory);

		// Присваиваем @Input()
		for (const [key, data] of Object.entries(componentData.inputs)) {
			componentRef.instance[key] = data;
		}
	}

	private destoryComponent(componentData: ModalityCloseInterface) {

		// this.modalityComponent.viewContainerRef.remove(0);

		if (componentData.all) {
			this.modalityComponent.viewContainerRef.clear();
		}

		this.modalityService.opened = false;
		this.scroll.enableScroll();
	}
}
