import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[modality]',
})
export class ModalityDirective {

	constructor(
		public viewContainerRef: ViewContainerRef
	) {}

}
