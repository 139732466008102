import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from './shared/services/authorization.service';
import { ApiService } from '../core/api/api.service';
import { AuthorizationLoginInterface } from './shared/interfaces/authorization-login.interface';

@Injectable()
export class AuthorizationGuard implements CanActivate {

	constructor(
		private router: Router,
		private authorizationService: AuthorizationService,
		private apiService: ApiService,
		private cookieService: CookieService
	) {}

	canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean | Promise<boolean> {

		const cookieAuth = this.cookieService.get('a');

		if (this.authorizationService.logged) {
			return true;
		}

		// Проверяем cookie
		if (!cookieAuth) {

			// Авторизация не пройдена
			this.router.navigate(['/', 'login']);
			return false;
		}

		return new Promise(async(resolve) => {

			try {

				// Делаем запрос на авторизацию
				const result: AuthorizationLoginInterface = await this.apiService.call('authorization.check', {
					cookie: cookieAuth
				}).toPromise();

				// Авторизовываемся
				this.authorizationService.login(result);
				resolve(true);
			} catch(error) {

				console.log(error);

				// Авторизация не пройдена
				this.router.navigate(['/', 'login']);
				resolve(false);
			}
		});
	}
}
