<div class="payout-preview">
	<div class="fixed-size">
		<div class="payout-preview-wrapper">
			<div class="payout-preview-title">Подать заявку на выплату</div>
			<div class="payout-preview-description">Вы можете подать заявку на оплату Вашей работы, но убедитесь, что Вы записали качественное видео <a href="//yadi.sk/i/ncWEv4HmkVlCGQ" target="_blank">(которое не нарушает условия работы с нами)</a> или провели крутой стрим!</div>
			<div class="payout-preview-button button button-large button-green" [routerLink]="['/', 'profile', 'payouts']">Мои заявки</div>
			<app-form class="payout-preview-form">
				<div class="form-field">
					<app-selector header="Выберите платформу" [items]="platforms" [(ngModel)]="platform"></app-selector>
				</div>
				<div class="form-field" *ngFor="let index of linkLength | toArray" [@Link]>
					<div class="form-field-wrapper">
						<app-input class="form-field-width" placeholder="Введите ссылку на ролик" [(ngModel)]="links[index]"></app-input>
						<div class="form-field-more"
						[class.more-add]="index === linkLength - 1"
						[class.more-del]="index !== linkLength - 1"
						(click)="updateLinks(index)"></div>
					</div>
				</div>
				<div class="form-field">
					<div class="form-field-wrapper">
						<app-selector class="form-field-width" header="Выберите валюту" [items]="currencies" [(ngModel)]="currency"></app-selector>
						<app-input class="form-field-width" type="text" placeholder="Цена" [(ngModel)]="money"></app-input>
					</div>
				</div>
				<div class="form-field">
					<div class="button button-large button-red" (click)="addPayout()">Создать заявку</div>
				</div>
			</app-form>
		</div>
	</div>
</div>