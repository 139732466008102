import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toArray' })
export class ToArrayPipe implements PipeTransform {

	transform(value: number): number[] {

		const array: number[] = new Array(Number(value));

		for (const [index, item] of array.entries()) {
			array[index] = index;
		}

		return array;
	}
}
