import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorComponent } from './selector/selector.component';
import { FormsModule } from '@angular/forms';
import { SelectorStyleService } from './selector-style.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [SelectorComponent],
    exports: [SelectorComponent],
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule
    ],
    providers: [
        SelectorStyleService
    ]
})
export class SelectorModule {}
