<div class="account-create-background" (click)="close()"></div>
<div class="account-create">
	<div class="account-create-header">
		<div class="account-create-header-title">Создайте новый аккаунт</div>
		<div class="account-create-header-close" (click)="close()"></div>
	</div>
	<div class="account-create-content">
		<div class="account-create-content-field">
			<div class="account-create-content-field-title">ВКонтакте</div>
			<div class="account-create-content-field-input">
				<app-input placeholder="Введите ВКонтакте пользователя" [(ngModel)]="form.userId"></app-input>
			</div>
		</div>
		<div class="account-create-content-field">
			<div class="account-create-content-field-title">Название канала</div>
			<div class="account-create-content-field-input">
				<app-input placeholder="Введите название канала" [(ngModel)]="form.channelName"></app-input>
			</div>
		</div>
		<div class="account-create-content-field">
			<div class="account-create-content-field-title">Ссылка на канал</div>
			<div class="account-create-content-field-input">
				<app-input placeholder="Введите ссылку на канал" [(ngModel)]="form.chanellLink"></app-input>
			</div>
		</div>
		<div class="account-create-content-field">
			<div class="button button-large button-green" (click)="createAccount()">Создать</div>
		</div>
	</div>
</div>
