import { Component, OnInit, Optional, Inject, PLATFORM_ID} from '@angular/core';
import { AuthorizationService } from 'src/app/authorization/shared/services/authorization.service';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../../core/api/api.service';
import { ProfileAccountInterface } from '../../shared/interfaces/profile-account.interface';
import { NotifierService } from '../../../ui/notifier/notifier.service';

@Component({
	selector: 'app-profile-account',
	templateUrl: './profile-account.component.html',
	styleUrls: ['./profile-account.component.scss']
})
export class ProfileAccountComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		public authorizationService: AuthorizationService,
		private apiService: ApiService,
		private notifierService: NotifierService
	) {}

	public account: ProfileAccountInterface;

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {

			this.init();
		}
	}

	private async init() {

		try {

			this.account = await this.apiService.call('account.get.my', {
				fields: ['id', 'channel_name', 'channel_link', 'payment_name', 'payment_contact', 'admin']
			}).toPromise();
		} catch(error) {

			// Ошибка запроса
			console.error(error);
			this.notifierService.update('Не удалось получить данные аккаунта', 'error', 5000);
		}
	}
}
