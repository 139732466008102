import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../../core/api/api.service';
import { NotifierService } from '../../../ui/notifier/notifier.service';
import { ProfilePayoutsInterface } from '../../shared/interfaces/profile-payouts.interface';

@Component({
	selector: 'app-profile-payouts',
	templateUrl: './profile-payouts.component.html',
	styleUrls: ['./profile-payouts.component.scss']
})
export class ProfilePayoutsComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService
	) {}

	public payouts: ProfilePayoutsInterface[];

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {

			this.getMyPayout();
		}
	}

	private async getMyPayout() {

		try {

			this.payouts = await this.apiService.call('payouts.get.my', {
				fields: ['id', 'money', 'currency_name', 'links', 'status']
			}).toPromise();
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
