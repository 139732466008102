import { Injectable } from "@angular/core";
import { AuthorizationLoginInterface } from '../interfaces/authorization-login.interface';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class AuthorizationService {

	constructor(
		private cookieService: CookieService,
		private router: Router
	) {}

	public date_logged: Date;
	public logged = false;

	public account: AuthorizationLoginInterface;

	public login(data: AuthorizationLoginInterface, cookie?: string) {

		// Если надо установить куки
		if (cookie) {
			this.cookieService.set('a', cookie, new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
		}

		this.logged = true;
		this.date_logged = new Date();

		this.account = data;
	}

	public exit() {

		this.cookieService.delete('a');

		this.logged = false;
		this.date_logged = null;
		
		this.account = null;

		this.router.navigate(['/', 'login']);
	}
}