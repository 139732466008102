import { Injectable, Inject, Optional } from '@angular/core';
import { SEOService } from './../seo/seo.service';
import { DefaultTitle, DefaultImage, DefaultDescription, DefaultKeywords } from './../../app-export.constant';
import { ScrollingService } from './../scrolling/scrolling.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NavigationStyleService } from 'src/app/ui/navigation/navigation-style.service';
import { InputStyleService } from 'src/app/ui/input/input-style.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { SelectorStyleService } from 'src/app/ui/selector/selector-style.service';
import { FormStyleService } from 'src/app/ui/form/form-style.service';
import { FooterStyleService } from 'src/app/ui/footer/footer-style.service';

@Injectable()
export class CustomRouteService {

    constructor(
        @Optional() @Inject(RESPONSE) private response: any,
        private seo: SEOService,
        private scroll: ScrollingService,
        private router: Router,
        private route: ActivatedRoute,

        private navigationStyleService: NavigationStyleService,
        private inputStyleService: InputStyleService,
        private selectorStyleService: SelectorStyleService,
        private formStyleService: FormStyleService,
        private footerStyleService: FooterStyleService
    ) {}

    public initCustomRoute() {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route),
            map((route) => {
              while (route.firstChild) { route = route.firstChild; }
              return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe((event: any) => {
            
            // Вызываем функцию обновления данных при переходе на страницу
            this.routeUpdate(event);
        });
    }

    private routeUpdate(event: any) {

        const isModality = event.modality?.component;
        const disableScrollToTopOnNavigation = event.disableScrollToTopOnNavigation === true;
        
        // Обновляем SEO информацию
        this.seo.updateTitle(event.title || DefaultTitle);
        this.seo.updateImage(event.image || DefaultImage);
        this.seo.updateDescription(event.description || DefaultDescription);
        this.seo.updateKeywords(event.keywords || DefaultKeywords);

        // Скроллим в самый вверх
        if (!disableScrollToTopOnNavigation && !isModality) {
            this.scroll.top(0);
        }

        // Устанавливаем стиль страницы
        this.navigationStyleService.setStyle(event.navigationStyle);
        this.inputStyleService.setStyle(event.inputStyle);
        this.selectorStyleService.setStyle(event.selectorStyle);
        this.formStyleService.setStyle(event.formStyle);
        this.footerStyleService.setStyle(event.footerStyle);
    }

    public updateResponse(code: number, message: string) {

		// Проверяем если получили ответ
		if (!this.response) {
			return false;
        }

		// Обновляем данные
		this.response.statusCode = code;
		this.response.statusText = message;
	}
}