import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './pages/profile/profile.component';
import { RouterModule } from '@angular/router';
import { ProfileAccountComponent } from './pages/profile-account/profile-account.component';
import { ProfilePayoutsComponent } from './pages/profile-payouts/profile-payouts.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { FormModule } from '../ui/form/from.module';
import { InputModule } from '../ui/input/input.module';
import { SelectorModule } from '../ui/selector/selector.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ProfileComponent,
        ProfileAccountComponent,
        ProfilePayoutsComponent,
        ProfileSettingsComponent
    ],
    exports: [ProfileComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        FormModule,
        InputModule,
        SelectorModule
    ]
})
export class ProfileModule { }
