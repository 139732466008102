import { Injectable } from "@angular/core";

@Injectable()
export class InputStyleService {
	
	public inputStyle: string;
	public inputStyles = [
		'input-white',
		'input-dark'
	];

	// Устанавливает указанный стиль, если стиль не существует - устанавливает дефолтный
	public setStyle(name: string) {
		this.inputStyle = this.inputStyles.find((style: string) => {
			return style === name;
		}) || this.inputStyles[0];
	}
}