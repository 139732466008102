<div class="admin-navigation-item" [routerLink]="['/', 'admin']" routerLinkActive="item-active" [routerLinkActiveOptions]="{ exact: true }">
	<div class="admin-navigation-item-icon icon-accounts"></div>
	<div class="admin-navigation-item-icon-title">Аккаунты</div>
</div>
<div *ngIf="authorizationService.account.accesses.includes('admin/payments')" class="admin-navigation-item" [routerLink]="['/', 'admin', 'payments']" routerLinkActive="item-active" [routerLinkActiveOptions]="{ exact: true }">
	<div class="admin-navigation-item-icon icon-payment"></div>
	<div class="admin-navigation-item-icon-title">Платёжные системы</div>
</div>
<div *ngIf="authorizationService.account.accesses.includes('admin/platforms')" class="admin-navigation-item" [routerLink]="['/', 'admin', 'platforms']" routerLinkActive="item-active" [routerLinkActiveOptions]="{ exact: true }">
	<div class="admin-navigation-item-icon icon-platform"></div>
	<div class="admin-navigation-item-icon-title">Платформы</div>
</div>
<div *ngIf="authorizationService.account.accesses.includes('admin/payouts')" class="admin-navigation-item" [routerLink]="['/', 'admin', 'payouts']" routerLinkActive="item-active" [routerLinkActiveOptions]="{ exact: true }">
	<div class="admin-navigation-item-icon icon-payouts"></div>
	<div class="admin-navigation-item-icon-title">Выплаты</div>
</div>
<div *ngIf="authorizationService.account.accesses.includes('admin/currencies')" class="admin-navigation-item" [routerLink]="['/', 'admin', 'currencies']" routerLinkActive="item-active" [routerLinkActiveOptions]="{ exact: true }">
	<div class="admin-navigation-item-icon icon-currency"></div>
	<div class="admin-navigation-item-icon-title">Валюты</div>
</div>