import { Injectable } from '@angular/core';

@Injectable()
export class SelectorStyleService {
	
	public selectorStyle: string;
	public selectorStyles = [
		'selector-white',
		'selector-dark'
	];

	// Устанавливает указанный стиль, если стиль не существует - устанавливает дефолтный
	public setStyle(name: string) {
		this.selectorStyle = this.selectorStyles.find((style: string) => {
			return style === name;
		}) || this.selectorStyles[0];
	}
}