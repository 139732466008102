
export const DeveloperMode = false;
export const ProjectUrl = (DeveloperMode ? 'http://localhost:4200/' : 'https://media.amazing.gg/');

export const APIDomain = (DeveloperMode ? 'http://localhost:5002' : 'https://api-media.amazing.gg');
export const APIMethods = APIDomain + '/' + (DeveloperMode ? 'test.method' : 'prod.method');
export const APIProject = APIMethods + '/';

export const DefaultTitle = 'AMAZING MEDIA — Зарабатывай играя у нас';
export const DefaultImage = '/assets/images/heads/index.png';
export const DefaultDescription = '';
export const DefaultKeywords = '';