import { Component, ElementRef, forwardRef, Input, ViewChild, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputStyleService } from '../input-style.service';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => InputComponent),
		multi: true
	}]
})

export class InputComponent implements ControlValueAccessor {

	@Input() type: string;
	@Input() placeholder: string;
	@Input() readonly: boolean;

	@ViewChild('inputRef', { static: true }) element: ElementRef;

	@HostBinding('class') get className() {
		return this.inputStyleService.inputStyle;
	}

	constructor(
		private inputStyleService: InputStyleService
	) {}

	writeValue(obj: any): void {
		this.element.nativeElement.value = obj || '';
		this.onChange(obj);
		this.onTouched(obj);
	}

	onChange = (_: string) => {};
	onTouched = (_: string) => {};
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}