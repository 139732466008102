import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthorizationGuard } from './authorization/authorization.guard';


const routes: Routes = [

	{
		path: 'login',
		loadChildren: () => import('./authorization/authorization-routing.module').then(m => m.AuthorizationRoutingModule)
	},

	// Основной сайт
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthorizationGuard],
		children: [

			// Главная страница
			{
				path: '',
				loadChildren: () => import('./index/index-routing.module').then(m => m.IndexRoutingModule)
			},

			// Подать новую выплату
			{
				path: '',
				loadChildren: () => import('./payout/payout-routing.module').then(m => m.PayoutRoutingModule)
			},

			// Профиль
			{
				path: '',
				loadChildren: () => import('./profile/profile-routing.module').then(m => m.ProfileRoutingModule)
			},

			// Панель администратора
			{
				path: '',
				loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule)
			},

			// 404
			{
				path: '',
				loadChildren: () => import('./not-found/not-found-routing.module').then(m => m.NotFoundRoutingModule)
			}
		]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
