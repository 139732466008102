<div class="admin-platforms">
	<div class="admin-platforms-title">Вы можете добавить новую платформу, удалить старую и посмотреть список всех платформ</div>
	<div class="admin-platforms-forms">
		<app-form class="admin-platforms-forms-form" header="Добавить новую">
			<div class="form-field">
				<app-input placeholder="Введите название платформы" [(ngModel)]="namePlatform"></app-input>
			</div>
			<div class="form-field">
				<app-input placeholder="Домен (начало ссылки)" [(ngModel)]="linkPlatform"></app-input>
			</div>
			<div class="form-field">
				<div class="button button-large button-green" (click)="addPlatform()">Добавить</div>
			</div>
		</app-form>
		<app-form class="admin-platforms-forms-form" header="Удалить старую">
			<div class="form-field">
				<app-selector header="Платформы" [items]="platforms" [(ngModel)]="deletePlatformData"></app-selector>
			</div>
			<div class="form-field">
				<div class="button button-large button-red" (click)="deletePlatform()">Удалить</div>
			</div>
		</app-form>
	</div>
</div>