import { Injectable, EventEmitter } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
} from '@angular/router';
import { ModalityService } from './shared/services/modality.service';

@Injectable()
export class ModalityGuard implements CanActivate {

	constructor(
		private router: Router,
		private modalityService: ModalityService
	) {}

	canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {

		const redirectUrl = activatedRouteSnapshot.data.modality?.redirectAfterClose || this.router.url;
		const isFirstRequest = activatedRouteSnapshot.data.modality?.firstRequest;
		const modalityComponent = activatedRouteSnapshot.data.modality?.component;
		const requireComponent = activatedRouteSnapshot.data.modality?.requiredComponent;
		const modalityParams = activatedRouteSnapshot.data.modality?.params || {};
		const firstNavigation = (this.router as any).navigationId === 1 || (this.router as any).lastSuccessfulId === -1;

		// Открываем модальное окно (запрет на первый запрос)
		if (isFirstRequest === false && firstNavigation) {
			this.router.navigateByUrl(redirectUrl);
			return true;
		}

		this.modalityService.visibleComponent(modalityComponent, Object.assign(modalityParams, activatedRouteSnapshot.params));

		this.modalityService.closeEvent.subscribe((componentData: any) => {
			this.router.navigateByUrl(redirectUrl);
		});

		if (requireComponent || firstNavigation) {
			return true;
		}

		return false;
	}
}
