import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from '../../../ui/notifier/notifier.service';
import { PaymentInterface } from '../../../core/interface/payment.interface';

@Component({
	selector: 'app-admin-payments',
	templateUrl: './admin-payments.component.html',
	styleUrls: ['./admin-payments.component.scss']
})
export class AdminPaymentsComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService
	) {}

	public payments: PaymentInterface[];

	// Переменные под формы
	public namePayment = '';
	public lengthPayment = '';
	public deletePaymentData: PaymentInterface;

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			this.init();
		}
	}

	private async init() {

		// Получаем все платёжные системы
		this.getPayments();
	}

	private async getPayments() {

		try {

			// Записываем платформы в переменную
			this.payments = await this.apiService.call('payment.get').toPromise();

		} catch(error) {

			console.error(error);
		}
	}

	// Функция добавления новой платёжной системы
	public async addPayment() {
		
		// Делаем запрос к API
		try {

			const result = await this.apiService.call('payment.add', {
				name: this.namePayment,
				length: this.lengthPayment
			}).toPromise();
			
			// Обнуляем значение переменной
			this.namePayment = this.lengthPayment = '';

			// Добавляем платформу
			this.payments.push(result);

			this.notifierService.update('Вы успешно добавили новую платёжную систему', 'success', 5000);
		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public async deletePayment() {

		// Делаем запрос к API
		try {


			await this.apiService.call('payment.delete', {
				id: (this.deletePaymentData?.id || '')
			}).toPromise();
			
			// Обнуляем значение переменной
			this.deletePaymentData = undefined;

			// Удаляем платформу
			this.payments.splice(this.payments.indexOf(this.deletePaymentData), 1);

			this.notifierService.update('Вы успешно удалили платёжную систему', 'success', 5000);
		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
