<div class="admin-currencies">
	<div class="admin-currencies-title">Вы можете добавлять, удалять и смотреть список валют</div>
	<div class="admin-currencies-forms">
		<app-form class="admin-currencies-forms-form" header="Добавить новую">
			<div class="form-field">
				<app-input placeholder="Введите название валюты" [(ngModel)]="nameCurrency"></app-input>
			</div>
			<div class="form-field">
				<div class="button button-large button-green" (click)="addCurrency()">Добавить</div>
			</div>
		</app-form>
		<app-form class="admin-currencies-forms-form" header="Удалить старую">
			<div class="form-field">
				<app-selector header="Валюты" [items]="currencies" [(ngModel)]="currencyPlatformData"></app-selector>
			</div>
			<div class="form-field">
				<div class="button button-large button-red" (click)="deleteCurrency()">Удалить</div>
			</div>
		</app-form>
	</div>
</div>