import { Component, Optional, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/ui/notifier/notifier.service';
import { ApiService } from '../../../core/api/api.service';
import { isPlatformBrowser } from '@angular/common';
import { PlatformInterface } from '../../../core/interface/platform.interface';
import { CurrencyInterface } from '../../../core/interface/currency.interface';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
	selector: 'app-payout',
	templateUrl: './payout.component.html',
	styleUrls: ['./payout.component.scss'],
	animations: [
		trigger('Link', [
			transition(':enter', [
				style({
					opacity: 0,
					'margin-bottom': 0,
					height: 0
				}),
				animate('400ms ease', style({
					opacity: 1,
					'margin-bottom': '*',
					height: '*'
				}))
			]),
			transition(':leave', [
				animate('400ms ease', style({
					opacity: 0,
					'margin-bottom': 0,
					height: 0
				}))
			])
		])
	]
})
export class PayoutComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private notifierService: NotifierService,
		private apiService: ApiService
	) {}

	public platforms: PlatformInterface[];
	public currencies: CurrencyInterface[];

	// Кол-во ссылок на ролик
	public linkLength = 1;

	// Данные под форму
	public platform: PlatformInterface;
	public links: string[] = [];
	public currency: CurrencyInterface;
	public money = '';

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {

			this.getPlatforms();
			this.getCurrencies();
		}
	}

	private async getCurrencies() {

		try {

			this.currencies = await this.apiService.call('currencies.get').toPromise();
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	private async getPlatforms() {

		try {

			this.platforms = await this.apiService.call('platform.get').toPromise();
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public updateLinks(itemId: number) {

		// Если это последний элемент
		if (itemId === this.linkLength - 1) {

			// Если уже 10 ссылок, выдаём ошибку
			if (this.linkLength > 9) {
				return this.notifierService.update('За один раз можно отправить не более 10 роликов', 'error', 5000);
			}

			// Добавляем
			return this.linkLength++;	
		}

		// Удаляем данные элемента
		this.links.splice(itemId, 1);

		// Иначе убавляем
		this.linkLength--;
	}

	public async addPayout() {

		try {

			await this.apiService.call('payout.add', {
				platform: (this.platform?.id || ''),
				links: this.links,
				currency: (this.currency?.id || ''),
				money: this.money
			}).toPromise();

			this.money = '';
			this.links = [];
			this.platform = this.currency = undefined;
			this.notifierService.update('Вы успешно подали заявку', 'success', 5000);
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
