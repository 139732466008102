<div class="settings">
    <div class="fixed-size">
        <div class="settings-wrapper">
            <div class="settings-title">Вы можете обновить данные платёжной системы, а также номер карты или телефона</div>
            <app-form class="settings-form">
                <div class="form-field">
                    <app-selector header="Выберите платёжную систему" [items]="payments" [(ngModel)]="paymentData"></app-selector>
                </div>
                <div class="form-field">
                    <app-input placeholder="Номер карты или телефона" [(ngModel)]="paymentContacts"></app-input>
                </div>
                <div class="forn-field">
                    <div class="button button-large button-green" (click)="updatePayments()">Обновить данные</div>
                </div>
            </app-form>
        </div>
    </div>
</div>