<div class="index-preview">
	<div class="fixed-size">
		<div class="index-preview-wrapper">
			<div class="index-preview-container">
				<div class="index-preview-title" [style.margin-bottom]="'65px'">Здесь можно создать заявку на оплату Вашей работы</div>
				<a class="index-preview-link button button-large button-green" [routerLink]="['/', 'payout']">Оставить заявку</a>
			</div>
			<div class="index-preview-container">
				<div class="index-preview-title">Вы можете заполнить или изменить свои данные для выплат в настройках аккаунта</div>
				<a class="index-preview-link button button-large button-red" [routerLink]="['/', 'profile', 'settings']">Перейти в настройки</a>
			</div>
		</div>
	</div>
</div>