import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminAuthorizationGuard } from './admin-authorization.guard';
import { AdminMainNavigationComponent } from './components/admin-main-navigation/admin-main-navigation.component';
import { AdminPlatformsComponent } from './pages/admin-platforms/admin-platforms.component';
import { FormModule } from '../ui/form/from.module';
import { FormsModule } from '@angular/forms';
import { InputModule } from '../ui/input/input.module';
import { SelectorModule } from '../ui/selector/selector.module';
import { AdminPaymentsComponent } from './pages/admin-payments/admin-payments.component';
import { AdminAccountsComponent } from './pages/admin-accounts/admin-accounts.component';
import { AdminAccountsEditComponent } from './pages/admin-accounts-edit/admin-accounts-edit.component';
import { AdminAccountCreateComponent } from './pages/admin-accounts-create/admin-accounts-create.component';
import { AdminPayoutsComponent } from './pages/admin-payouts/admin-payouts.component';
import { AdminCurrenciesComponent } from './pages/admin-currencies/admin-currencies.component';
import { DirectiveModule } from '../core/directive/directive.module';
import { AdminPayoutsInfoComponent } from './pages/admin-payouts-info/admin-payouts-info.component';
import { AdminPayoutsInfoBlockComponent } from './components/admin-payouts-info/admin-payouts-info.component';
import { AdminPayoutsInfoService } from './shared/services/admin-payouts-info.service';
import { AdminPayoutsApprovedComponent } from './pages/admin-payouts-approved/admin-payouts-approved.component';

@NgModule({
    declarations: [
        AdminComponent,
        AdminMainNavigationComponent,
        AdminPlatformsComponent,
        AdminPaymentsComponent,
        AdminAccountsComponent,
        AdminAccountsEditComponent,
        AdminAccountCreateComponent,
        AdminPayoutsComponent,
        AdminPayoutsInfoComponent,
        AdminCurrenciesComponent,
        AdminPayoutsInfoBlockComponent,
        AdminPayoutsApprovedComponent
    ],
    exports: [AdminComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormModule,
        FormsModule,
        InputModule,
        SelectorModule,
        DirectiveModule
    ],
    providers: [
        AdminAuthorizationGuard,
        AdminPayoutsInfoService
    ]
})
export class AdminModule { }
