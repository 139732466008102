import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormStyleService } from './form-style.service';
import { FormComponent } from './form/form.component';

@NgModule({
	declarations: [FormComponent],
	exports: [FormComponent],
	imports: [
		CommonModule
	],
	providers: [FormStyleService]
})
export class FormModule {}