import { Component } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from '../../../ui/notifier/notifier.service';
import { ModalityService } from './../../../modality/shared/services/modality.service';

@Component({
	selector: 'app-admin-accounts-create',
	templateUrl: './admin-accounts-create.component.html',
	styleUrls: ['./admin-accounts-create.component.scss']
})
export class AdminAccountCreateComponent {

	constructor(
		private apiService: ApiService,
		private notifierService: NotifierService,
		private modalityService: ModalityService
	) {}

	// Переменные под форму
	public form = {
		userId: '',
		channelName: '',
		chanellLink: ''
	}

	public async createAccount() {
		
		try {

			await this.apiService.call('account.create', {
				link_vk: this.form.userId,
				channel_name: this.form.channelName,
				channel_link: this.form.chanellLink
			}).toPromise();

			this.notifierService.update('Вы успешно создали новый аккаунт', 'success', 5000);
			this.close();

		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public close() {

		this.modalityService.destroyComponent();
	}
}
