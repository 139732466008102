import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApplicationId } from '../../shared/constants/authorization.constants';
import { ProjectUrl } from '../../../app-export.constant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * @description Login as VK application
   * 
   * @returnType void
   */
  login() {
    
    // Перенаправляем на авторизацию ВК
    this.document.location.href = 'https://oauth.vk.com/authorize?client_id=' + ApplicationId + '&redirect_uri=' + ProjectUrl + 'login';
  }
}
