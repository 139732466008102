import { Injectable, EventEmitter } from '@angular/core';
import { ModalityOpenInterface, ModalityCloseInterface } from '../interfaces/modality.interfaces';

@Injectable()
export class ModalityService {

	public opened = false;

	// Ивенты
	public openEvent: EventEmitter<ModalityOpenInterface> = new EventEmitter();
	public closeEvent: EventEmitter<ModalityCloseInterface> = new EventEmitter();

	// Если 1-й роутинг - модальное окно, записываем сюда
	public component: ModalityOpenInterface;

	public visibleComponent(modalityComponent: any, inputs?: any) {

		// Записываем в переменную (если первая инициализация)
		if (this.component === undefined) {
			this.component = {
				component: modalityComponent,
				inputs: inputs
			}
		}

		// Отправляем открытие окна компоненту
		this.openEvent.emit({
			component: modalityComponent,
			inputs: inputs
		});
	}

	public destroyComponent(modalityNumber?: number) {

		// Отправляем событие
		this.closeEvent.emit({
			// id: modalityNumber,
			all: true
		});
	}
}
