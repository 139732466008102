import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { FormsModule } from '@angular/forms';
import { InputStyleService } from './input-style.service';

@NgModule({
    declarations: [
        InputComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        InputComponent
    ],
    providers: [
        InputStyleService
    ]
})
export class InputModule {
}
