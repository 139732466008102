<div class="account">
	<div class="fixed-size">
		<div class="account-wrapper">
			<div class="account-header">
				<div class="account-header-title">{{ authorizationService.account.name }}</div>
			</div>
			<div class="account-content">
				<div class="account-content-header">
					<div class="account-content-header-title">Информация об аккаунте</div>
				</div>
				<div class="account-content-section">
					<div class="account-content-section-row">
						<div class="account-content-section-title">Номер аккаунта</div>
						<div class="account-content-section-description">#{{ account?.id }}</div>
					</div>
					<div class="account-content-section-row" *ngIf="account?.admin.level">
						<div class="account-content-section-title">Права администратора</div>
						<div class="account-content-section-description">{{ account?.admin.level }} ({{ account?.admin.name }})</div>
					</div>
					<div class="account-content-section-row">
						<div class="account-content-section-title">ВКонтакте</div>
						<div class="account-content-section-description">
							<a href="{{ 'https://vk.com/id' + account?.vk.id }}" target="_blank">{{ account?.vk.first_name }} {{ account?.vk.last_name }}</a>
						</div>
					</div>
					<div class="account-content-section-row">
						<div class="account-content-section-title">Канал</div>
						<div class="account-content-section-description">
							<a href="{{ account?.channel_link }}" target="_blank">{{ account?.channel_name || 'Отсутствует' }}</a>
						</div>
					</div>
					<div class="account-content-section-row">
						<div class="account-content-section-title">Система вывода</div>
						<div class="account-content-section-description">{{ account?.payment_name || 'Не выбрано' }}</div>
					</div>
					<div class="account-content-section-row">
						<div class="account-content-section-title">Платёжные данные</div>
						<div class="account-content-section-description">{{ account?.payment_contact || 'Нет данных' }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>