import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { ToArrayPipe } from './pipes/to-array.pipe';

@NgModule({
    declarations: [
        SafePipe,
        ToArrayPipe
    ],
    exports: [
        SafePipe,
        ToArrayPipe
    ],
    imports: [
        CommonModule
    ]
})
export class PipeModule {}
