<div class="payouts">
	<div class="fixed-size">
		<div class="payouts-wrapper">
			<div class="payouts-list">
				<div class="payouts-list-header">
					<div class="payouts-list-header-title">Список всех заявлений на выплату</div>
				</div>
				<div class="payouts-list-content">
					<div class="payouts-list-content-item" *ngFor="let payout of payouts">
						<a *ngFor="let link of payout?.links" [href]="'//' + link" target="_blank" class="item-button button button-small button-white">{{ link }}</a>
						<div class="item-container container-white">{{ payout?.money }} {{ payout.currency_name }}</div>
						<div class="item-container" [style.background-color]="payout?.status.color">{{ payout?.status.name }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>