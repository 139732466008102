import { Component, HostBinding, Input } from '@angular/core';
import { FormStyleService } from '../form-style.service';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent {

	constructor(
		private formStyleService: FormStyleService
	) {}

	@Input() public header: string;
	
	@HostBinding('class') get className() {
		return this.formStyleService.formStyle;
	}
}