<div class="payout-block">
	<div class="payout-block-arrow arrow-left" (click)="adminPayoutsInfoService.back()"></div>
	<div class="payout-block-content">
		<div class="payout-block-title">Заявление №{{ item.id }}, канал {{ item.channelName }}</div>
		<div class="payout-block-field">
			<div class="payout-block-field" *ngFor="let link of item.links">
				<div class="payout-block-field-title">Ссылка на видео</div>
				<a class="payout-block-field-content content-click" [href]="'//' + link" target="_blank">{{ link }}</a>
			</div>
			<div class="payout-block-field">
				<div class="payout-block-field-title">Платёжные данные</div>
				<div class="payout-block-field-content content-click" copy-text [copy-text-input]="item.contact">{{ item.payment_name }}: {{ item.contact }}</div>
			</div>
			<div class="payout-block-field">
				<div class="payout-block-field-title">Сумма</div>
				<div class="payout-block-field-content">{{ item.money }} {{ item.currency_name }}</div>
			</div>
			<div class="payout-block-field">
				<div class="payout-block-field-title">Дата заявления</div>
				<div class="payout-block-field-content">{{ item.date | date: 'medium' }}</div>
			</div>
			<div class="payout-block-field">
				<div class="payout-block-field-title">Статус</div>
				<app-selector [items]="statuses" [ngModel]="item.status"(ngModelChange)="chengeStatus($event, item)"></app-selector>
			</div>
			<div class="payout-block-field">
				<div class="payout-block-field-title">Проверил</div>
				<div class="payout-block-field-content">{{ item.check_vk?.first_name || 'Не проверено' }} {{ item.check_vk?.last_name }}</div>
			</div>
			<div class="payout-block-field">
				<div class="payout-block-field-title">Дата проверки</div>
				<div class="payout-block-field-content">{{ (item.check_date | date: 'medium') || 'Не проверено' }}</div>
			</div>
		</div>
	</div>
	<div class="payout-block-arrow arrow-right" (click)="adminPayoutsInfoService.next()"></div>
</div>