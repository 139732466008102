import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AppRoutingModule } from './app-routing.module';
import { CustomRouteModule } from './core/custom-route/custom-route.module';
import { SEOModule } from './core/seo/seo.module';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from './core/scrolling/scrolling.module';
import { ApiModule } from './core/api/api.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AuthorizationModule } from './authorization/authorization.module';
import { NotifierModule } from './ui/notifier/notifier.module';
import { IndexModule } from './index/index.module';
import { NotFoundModule } from './not-found/not-found.module';
import { PayoutModule } from './payout/payout.module';
import { ProfileModule } from './profile/profile.module';
import { AdminModule } from './admin/admin.module';
import { ModalityModule } from './modality/modality.module';
import { CookieService } from 'ngx-cookie-service';

registerLocaleData(localeRu, 'ru');

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'agg-media-frontend' }),
		AppRoutingModule,
		HttpClientModule,
		CustomRouteModule,
		SEOModule,
		ScrollingModule,
		ApiModule,
		LayoutModule,
		AuthorizationModule,
		NotifierModule,
		IndexModule,
		PayoutModule,
		ProfileModule,
		AdminModule,
		ModalityModule,
		NotFoundModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'ru' },
		CookieService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
