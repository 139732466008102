import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { FooterStyleService } from './footer-style.service';

@NgModule({
	declarations: [FooterComponent],
	exports: [FooterComponent],
	imports: [
		CommonModule,
		RouterModule
	],
	providers: [
		FooterStyleService
	]
})
export class FooterModule {
}
