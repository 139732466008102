import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifierComponent } from './notifier/notifier.component';
import { NotifierService } from './notifier.service';

@NgModule({
	declarations: [
		NotifierComponent
	],
	exports: [
		NotifierComponent
	],
	imports: [
		CommonModule
	],
	providers: [
		NotifierService
	]
})
export class NotifierModule {
}
