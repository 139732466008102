import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {

    constructor(
		private title: Title,
		private meta: Meta
	) {}

	// Обновляет заголовок страницы
	updateTitle(title: string) {
		this.title.setTitle(title);
	}

	// Обновляет ссылку на превью-картинку страницы
	updateImage(url: string) {
		this.meta.updateTag({property: 'og:image', content: url});
	}

	// Обновляет описание страницы
	updateDescription(desc: string) {
		this.meta.updateTag({name: 'description', content: desc});
		this.meta.updateTag({name: 'og:description', content: desc});
	}

	// Обновляет тэги страницы
	updateKeywords(keys: string) {
		this.meta.updateTag({name: 'keywords', content: keys});
	}
}