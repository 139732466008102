import { Injectable } from '@angular/core';

@Injectable()
export class NavigationStyleService {

	public navigationStyle: string;
	public navigationStyles = [
		'navigation-transparent',
		'navigation-white',
		'navigation-fused-gray'
	];

	// Устанавливает указанный стиль, если стиль не существует - устанавливает дефолтный
	public setStyle(name: string) {
		this.navigationStyle = this.navigationStyles.find((style: string) => {
			return style === name;
		}) || this.navigationStyles[0];
	}
}
