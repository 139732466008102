<div class="fixed-size">
	<div class="navigation">
		<div class="navigation-side">
			<a class="navigation-item" [routerLink]="['/']">Главная</a>
			<a class="navigation-item" [routerLink]="['/', 'payout']">Выплата</a>
			<a class="navigation-item" [routerLink]="['/', 'profile', 'payouts']">Мои заявки</a>
			<a *ngIf="authorizationService.account.accesses.includes('admin')" class="navigation-item" [routerLink]="['/', 'admin']">Панель администратора</a>
		</div>
		<div class="navigation-side mobile-side">
			<div class="navigation-button button" [routerLink]="['/', 'profile']">{{ authorizationService.account.name }}</div>
			<div class="navigation-button button" (click)="authorizationService.exit()">Выход</div>
		</div>
	</div>
</div>