import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ApiService } from '../core/api/api.service';
import { AuthorizationService } from '../authorization/shared/services/authorization.service';

@Injectable()
export class AdminAuthorizationGuard implements CanActivate {

	constructor(
		private router: Router,
		private apiService: ApiService,
		private authorizationService: AuthorizationService
	) {}

	canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {

		const path = activatedRouteSnapshot.pathFromRoot.map((item) => {
			return item.routeConfig?.path.toString();
		}).filter((item) => {
			return item && item.length > 0
		}).join('/');

		return this.authorizationService.account.accesses.includes(path);

		/* return new Promise(async(resolve) => {

			console.log('[AdminAuthorizationGuard]: Accessing... (' + path + ')');

			try {

				// Проверяем на разрешённый доступ
				await this.apiService.call('access.get', {
					path: path
				}).toPromise();

				resolve(true);

			} catch(error) {

				this.router.navigate(['/', 'not-found']);
				resolve(false);
			}
		}); */
	}
}
