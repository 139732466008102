<div class="payout-info-background"></div>
<div class="payout-info-header">
	<div class="fixed-size">
		<div class="payout-info-header-wrapper">
			<div class="payout-info-header-title">Заявления
				<a [href]="'//vk.com/id' + payouts[0]?.vk.id" target="_blank">{{ payouts[0]?.vk.first_name }} {{ payouts[0]?.vk.last_name }}</a>
				</div>
			<div class="payout-info-header-side">
				<app-selector class="payout-info-header-selector" [items]="statuses" [(ngModel)]="searchStatus" (ngModelChange)="sortPayouts()"></app-selector>
				<div class="payout-close" (click)="close()"></div>
			</div>
		</div>
	</div>
</div>
<div class="payout-info"[style.transform]="'translateX(' + adminPayoutsInfoService.translateX + 'px)'">
	<app-admin-payouts-info-block
		*ngFor="let item of payouts; let index = index"
		[item]="item" [statuses]="statuses"
		[class.active]="index === adminPayoutsInfoService.activePayout">
	</app-admin-payouts-info-block>
</div>