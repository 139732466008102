import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutComponent } from './pages/payout/payout.component';
import { FormModule } from '../ui/form/from.module';
import { InputModule } from '../ui/input/input.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectorModule } from '../ui/selector/selector.module';
import { PipeModule } from '../core/pipe/pipe.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [PayoutComponent],
    exports: [PayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        FormsModule,
        FormModule,
        InputModule,
        SelectorModule,
        PipeModule
    ]
})
export class PayoutModule { }
