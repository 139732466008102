<div class="account-edit-background" (click)="close()"></div>
<div class="account-edit">
	<div class="account-edit-header">
		<div class="account-edit-header-title">Редактирование аккаунта {{ account?.vk.first_name }} {{ account?.vk.last_name }}</div>
		<div class="account-edit-header-close" (click)="close()"></div>
	</div>
	<div class="account-edit-content">
		<div class="account-edit-content-field">
			<div class="account-edit-content-field-title">ВКонтакте</div>
			<div class="account-edit-content-field-input">
				<app-input placeholder="Введите ВКонтакте пользователя" [(ngModel)]="form.userId"></app-input>
			</div>
		</div>
		<div class="account-edit-content-field">
			<div class="account-edit-content-field-title">Название канала</div>
			<div class="account-edit-content-field-input">
				<app-input placeholder="Введите название канала" [(ngModel)]="form.channelName"></app-input>
			</div>
		</div>
		<div class="account-edit-content-field">
			<div class="account-edit-content-field-title">Ссылка на канал</div>
			<div class="account-edit-content-field-input">
				<app-input placeholder="Введите ссылку на канал" [(ngModel)]="form.chanellLink"></app-input>
			</div>
		</div>
		<div class="account-edit-content-field">
			<div class="account-edit-content-field-title">Права администратора</div>
			<div class="account-edit-content-field-input">
				<app-selector header="Уровень администратора" [items]="admins" [(ngModel)]="form.admin"></app-selector>
			</div>
		</div>
		<div class="account-edit-content-field">
			<div class="button button-large button-green" (click)="updateAccount()">Обновить</div>
		</div>
		<div class="account-edit-content-field">
			<div class="button button-large button-red" (click)="deleteAccount()">Удалить</div>
		</div>
	</div>
</div>