import { Injectable } from '@angular/core';

@Injectable()
export class FormStyleService {
	
	public formStyle: string;
	public formStyles = [
		'form-white',
		'from-dark'
	];

	// Устанавливает указанный стиль, если стиль не существует - устанавливает дефолтный
	public setStyle(name: string) {
		this.formStyle = this.formStyles.find((style: string) => {
			return style === name;
		}) || this.formStyles[0];
	}
}