import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRouteService } from './custom-route.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    CustomRouteService
  ]
})
export class CustomRouteModule {

    constructor(
        private customRouteService: CustomRouteService
    ) {
        this.customRouteService.initCustomRoute();
    }
}
