import { Directive, Inject, HostListener, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NotifierService } from './../../../ui/notifier/notifier.service';

@Directive({
	selector: '[copy-text]',
})
export class CopyTextDirective {

	constructor(
        @Inject(DOCUMENT) private document: Document,
        private notifierService: NotifierService
    ) {}

    @Input('copy-text-input') private inputText: string;

    @HostListener('mouseup', ['$event.target.innerText'])
    @HostListener('touchend', ['$event.target.innerText']) onClick(text: string) {
        this.copyToClipboard(this.inputText || text);

        this.notifierService.update('Успешно скопировано', 'success', 5000);
    }

    private copyToClipboard(value: string) {
        this.document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (value));
            e.preventDefault();
            this.document.removeEventListener('copy', null);
        });
    
        this.document.execCommand('copy');
    }
    

}
