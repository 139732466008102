import { Injectable } from '@angular/core';

@Injectable()
export class FooterStyleService {

    public footerStyle: string;
	public footerStyles = [
		'footer-white',
		'footer-dark'
	];

	// Устанавливает указанный стиль, если стиль не существует - устанавливает дефолтный
	public setStyle(name: string) {
		this.footerStyle = this.footerStyles.find((style: string) => {
			return style === name;
		}) || this.footerStyles[0];
	}
}