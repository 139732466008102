import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from './../../../ui/notifier/notifier.service';
import { AdminAccountsInterface } from '../../shared/interfaces/admin-accounts.interfaces';
import { AuthorizationService } from './../../../authorization/shared/services/authorization.service';

@Component({
	selector: 'app-admin-accounts',
	templateUrl: './admin-accounts.component.html',
	styleUrls: ['./admin-accounts.component.scss']
})
export class AdminAccountsComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService,
		public authorizationService: AuthorizationService
	) {}

	public accounts: AdminAccountsInterface[];

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			
			this.getAccounts();
		}
	}

	private async getAccounts() {

		try {

			this.accounts = await this.apiService.call('accounts.get', {
				fields: ['id', 'admin']
			}).toPromise();
		} catch(error) {
			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
