import { Component, OnInit } from '@angular/core';
import { CustomRouteService } from '../../../core/custom-route/custom-route.service';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

	constructor(
		private customRoute: CustomRouteService
	) {}

	ngOnInit() {

		// Устанавливаем 404
		this.customRoute.updateResponse(404, 'Not Found');
	}
}
