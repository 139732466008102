import { Component, Optional, Inject, PLATFORM_ID, Input, AfterViewInit, HostListener } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from '../../../ui/notifier/notifier.service';
import { ModalityService } from '../../../modality/shared/services/modality.service';
import { AdminPayoutsInfoInterface } from '../../shared/interfaces/admin-payouts-info.interface';
import { StatusInterface } from 'src/app/core/interface/status.interface';
import { AdminPayoutsInfoService } from '../../shared/services/admin-payouts-info.service';

@Component({
	selector: 'app-admin-payouts-approved',
	templateUrl: './admin-payouts-approved.component.html',
	styleUrls: ['./admin-payouts-approved.component.scss']
})
export class AdminPayoutsApprovedComponent implements AfterViewInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		private apiService: ApiService,
		private notifierService: NotifierService,
		private modalityService: ModalityService,
		public adminPayoutsInfoService: AdminPayoutsInfoService
	) {}

	public payouts: AdminPayoutsInfoInterface[] = [];
	public statuses: StatusInterface[];

	// Под сортировку
	public searchStatus: StatusInterface;

	ngAfterViewInit() {

		if (isPlatformBrowser(this.platformId)) {
			
			this.getPayout();
			this.getStatuses();
		}
	}

	private async getPayout() {

		try {

			this.payouts = await this.apiService.call('payout.get.users.approved', {
				fields: ['id', 'links', 'money', 'currency_name', 'payment_name', 'contact', 'date', 'check_date', 'status', 'channelName']
			}).toPromise();

			// Инициализация
			this.adminPayoutsInfoService.init(this.payouts.length);
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
			this.close();
		}
	}

	public async getStatuses() {

		try {

			this.statuses = await this.apiService.call('payout.get.statuses', {
				useAccess: true
			}).toPromise();

			this.searchStatus = { id: -1, name: 'Сортировка', color: 'black' };
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
			this.close();
		}
	}

	public close() {
		this.modalityService.destroyComponent();

		// Сбрасываем данные
		this.adminPayoutsInfoService.clear();
	}

	@HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {

		console.log(event);

		// Стрелка вправо
		if (event.keyCode === 39) {
			this.adminPayoutsInfoService.next();
		}

		// Стрелка влево
		if (event.keyCode === 37) {
			this.adminPayoutsInfoService.back();
		}
	}
}