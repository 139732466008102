import { Component, forwardRef, Input, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { trigger, style, transition, animate } from '@angular/animations';
import { SelectorStyleService } from '../selector-style.service';
import { SelectorItemsInterface } from './../shared/interfaces/selected-items.interface';

@Component({
	selector: 'app-selector',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SelectorComponent),
		multi: true
	}],
	animations: [
		trigger('ActiveItems', [
			transition(':enter', [
				style({ 'margin-top': '20px', opacity: 0 }),
				animate('100ms', style({ 'margin-top': '*', opacity: 1 }))
			]),
			transition(':leave', [
				animate('100ms', style({ 'margin-top': '20px', opacity: 0 }))
			])
		])
	]
})

export class SelectorComponent implements ControlValueAccessor {

	constructor(
		private selectorStyleService: SelectorStyleService
	) {}

	@HostBinding('class') get className() {
		return this.selectorStyleService.selectorStyle;
	}

	@Input() public header: string;
	@Input() public items: SelectorItemsInterface[];

	public itemsShown = false;
	public activeItem: SelectorItemsInterface;

	// Обновляем закрытое / открытое окно
	public showItems() {
		this.itemsShown = !this.itemsShown;
	}

	// Обновляем активный блок
	public updateActiveItem(item: SelectorItemsInterface, system = false) {

		this.activeItem = item;

		if (!system) {
			this.showItems();
		}

		this.onChange(item);
		this.onTouched(item);
	}

	writeValue(item: SelectorItemsInterface): void {
		this.updateActiveItem(item, true);
	}

	onChange = (_: any) => {};
	onTouched = (_: any) => {};
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}