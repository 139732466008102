<div class="admin-payouts">
	<div class="admin-payouts-title">Посмотрите список всех заявлений на выплату</div>
	<div class="admin-payouts-list">
		<div class="admin-payouts-list-header">
			<div class="admin-payouts-list-header-title">Список всех заявлений</div>
			<div class="admin-payouts-list-header-button button button-small button-blue" [routerLink]="['/', 'admin', 'payouts', 'approved']">Общий список</div>
		</div>
		<div class="admin-payouts-list-content">
			<div class="admin-payouts-list-content-item" *ngFor="let payout of payouts">
				<a [href]="payout?.channel_link" target="_blank" class="item-container container-clickable">
					<div *ngFor="let status of payout.statuses" class="item-container-color" [style.background-color]="status.color"></div>
					{{ payout?.channel_name }}
				</a>
				<a class="item-button button button-small button-blue" [routerLink]="['/', 'admin', 'payouts', 'info', payout?.id]">Список заявлений</a>
			</div>
		</div>
	</div>
</div>