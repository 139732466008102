<div class="admin-accounts">
	<div class="admin-accounts-title">Так как Вы являетесь администратором сайта, Вы можете просматривать все аккаунты и редактировать их</div>
	<div *ngIf="authorizationService.account.accesses.includes('admin/account/create')" class="admin-accounts-button button button-large button-green" [routerLink]="['/', 'admin', 'account', 'create']">Создать аккаунт</div>
	<div class="admin-accounts-list">
		<div class="admin-accounts-list-header">
			<div class="admin-accounts-list-header-title">Список всех аккаунтов</div>
			<!-- <div class="admin-accounts-list-header-search">
				<input class="admin-accounts-list-header-search-input" type="text" placeholder="Начните поиск">
				<div class="admin-accounts-list-header-search-icon"></div>
			</div> -->
		</div>
		<div class="admin-accounts-list-content">
			<div class="admin-accounts-list-content-item" *ngFor="let account of accounts">
				<div class="item-container">
					<div class="container-color" [style.background-color]="account?.admin.color"></div>
					<div class="container-title">{{ account?.vk.first_name }} {{ account?.vk.last_name }}</div>
				</div>
				<div class="item-button button button-small button-blue" *ngIf="authorizationService.account.accesses.includes('admin/account/edit/:userId')" [routerLink]="['/', 'admin', 'account', 'edit', account?.vk.id]">Редактировать</div>
			</div>
		</div>
	</div>
</div>