<div class="selector-active" (click)="showItems()">
	<div class="selector-active-side">
		<div class="selector-active-color" *ngIf="activeItem?.color" [style.background-color]="activeItem.color"></div>
		<div class="selector-active-title">{{ activeItem ? activeItem.name : header }}</div>
	</div>
	<div class="selector-active-icon" [class.icon-active]="itemsShown"></div>
</div>
<div class="selector-items" *ngIf="itemsShown" [@ActiveItems]>
	<div *ngFor="let item of items" class="selector-items-item" (click)="updateActiveItem(item)">
		<div class="selector-items-item-color" *ngIf="item.color" [style.background-color]="item.color"></div>
		<div class="selector-items-item-title">{{ item.name }}</div>
	</div>
</div>