import { Injectable } from '@angular/core';

@Injectable()
export class NotifierService {

	public shown = false;
	public text = '';
	public type = '';
	private timer;

	update(text: string, type: string, timer: number) {

		this.shown = true;
		this.text = text || '';
		this.type = type || '';

		if (this.timer) {
			clearTimeout(this.timer);
		}

		if (timer) {
			this.timer = setTimeout(() => {
				this.shown = false;
			}, timer);
		}
	}
}
