import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './pages/index/index.component';
import { InputModule } from '../ui/input/input.module';
import { FormsModule } from '@angular/forms';
import { SelectorModule } from '../ui/selector/selector.module';
import { FormModule } from '../ui/form/from.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [IndexComponent],
    exports: [IndexComponent],
    imports: [
        CommonModule,
        RouterModule,
        InputModule,
        SelectorModule,
        FormsModule,
        FormModule
    ]
})
export class IndexModule { }
