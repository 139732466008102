import { Component, HostBinding } from '@angular/core';
import { FooterStyleService } from '../footer-style.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

	constructor(
		private footerStyleService: FooterStyleService
	) {}

	@HostBinding('class') get className() {
		return this.footerStyleService.footerStyle;
	}
}
