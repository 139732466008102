import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from './../../../ui/notifier/notifier.service';
import { PlatformInterface } from '../../../core/interface/platform.interface';

@Component({
	selector: 'app-admin-platforms',
	templateUrl: './admin-platforms.component.html',
	styleUrls: ['./admin-platforms.component.scss']
})
export class AdminPlatformsComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService
	) {}

	public platforms: PlatformInterface[];

	// Переменные под формы
	public namePlatform = '';
	public linkPlatform = '';
	public deletePlatformData: PlatformInterface;

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {

			this.init();
		}
	}

	private init() {

		// Получаем все платформы
		this.getPlatforms();
	}

	private async getPlatforms() {
		
		try {

			// Записываем платформы в переменную
			this.platforms = await this.apiService.call('platform.get').toPromise();

		} catch(error) {

			console.error(error);
		}
	}

	// Функция добавления новой платформы
	public async addPlatform() {
		
		// Делаем запрос к API
		try {

			const result = await this.apiService.call('platform.add', {
				name: this.namePlatform,
				link: this.linkPlatform
			}).toPromise();
			
			// Обнуляем значение переменной
			this.namePlatform = '';

			// Добавляем платформу
			this.platforms.push(result);

			this.notifierService.update('Вы успешно добавили новую платформу', 'success', 5000);
		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public async deletePlatform() {

		// Делаем запрос к API
		try {


			const result = await this.apiService.call('platform.delete', {
				id: (this.deletePlatformData?.id || '')
			}).toPromise();
			
			// Обнуляем значение переменной
			this.deletePlatformData = undefined;

			// Удаляем платформу
			this.platforms.splice(this.platforms.indexOf(this.deletePlatformData), 1);

			this.notifierService.update('Вы успешно удалили платформу', 'success', 5000);
		} catch(error) {

			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
