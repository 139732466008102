import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalityComponent } from './component/modality.component';
import { ModalityDirective } from './shared/directives/modality.directive';
import { ModalityService } from './shared/services/modality.service';
import { ModalityGuard } from './modality.guard';

@NgModule({
	declarations: [
		ModalityComponent,
		ModalityDirective
	],
	exports: [ModalityComponent],
	imports: [
		CommonModule
	],
	providers: [
		ModalityService,
		ModalityGuard
	]
})
export class ModalityModule {}
