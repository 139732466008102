<div class="payout-info-background"></div>
<div class="payout-info-header">
	<div class="fixed-size">
		<div class="payout-info-header-wrapper">
			<div class="payout-info-header-title">Заявления, ожидающие выплаты</div>
			<div class="payout-info-header-side">
				<div class="payout-close" (click)="close()"></div>
			</div>
		</div>
	</div>
</div>
<div class="payout-info"[style.transform]="'translateX(' + adminPayoutsInfoService.translateX + 'px)'">
	<app-admin-payouts-info-block
		*ngFor="let item of payouts; let index = index"
		[item]="item" [statuses]="statuses"
		[class.active]="index === adminPayoutsInfoService.activePayout">
	</app-admin-payouts-info-block>
</div>