import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from 'src/app/core/api/api.service';
import { NotifierService } from './../../../ui/notifier/notifier.service';
import { CurrencyInterface } from '../../../core/interface/currency.interface';

@Component({
	selector: 'app-admin-platforms',
	templateUrl: './admin-currencies.component.html',
	styleUrls: ['./admin-currencies.component.scss']
})
export class AdminCurrenciesComponent implements OnInit {

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService
	) {}

	public currencies: CurrencyInterface[];

	// Переменные под формы
	public nameCurrency = '';
	public currencyPlatformData: CurrencyInterface;

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {

			this.getCurrencies();
		}
	}

	private async getCurrencies() {
		
		try {

			// Записываем платформы в переменную
			this.currencies = await this.apiService.call('currencies.get').toPromise();

		} catch(error) {

			console.error(error);
		}
	}

	public async addCurrency() {

		try {

			const result = await this.apiService.call('currency.add', {
				name: this.nameCurrency
			}).toPromise();

			this.nameCurrency = '';

			this.currencies.push(result);

			this.notifierService.update('Вы успешно добавили новую платформу', 'success', 5000);

		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public async deleteCurrency() {

		// Делаем запрос к API
		try {


			const result = await this.apiService.call('currency.delete', {
				id: (this.currencyPlatformData?.id || '')
			}).toPromise();
			
			// Обнуляем значение переменной
			this.currencyPlatformData = undefined;

			// Удаляем платформу
			this.currencies.splice(this.currencies.indexOf(this.currencyPlatformData), 1);

			this.notifierService.update('Вы успешно удалили валюту', 'success', 5000);
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}
}
