<div class="admin-payments">
	<div class="admin-payments-title">Вы можете добавить новую платёжную систему, удалить старую и посмотреть список всех систем
	</div>
	<div class="admin-payments-forms">
		<app-form class="admin-payments-forms-form" header="Добавить новую">
			<div class="form-field">
				<app-input placeholder="Введите название системы" [(ngModel)]="namePayment"></app-input>
			</div>
			<div class="form-field">
				<app-input placeholder="Длина символов на карте" [(ngModel)]="lengthPayment"></app-input>
			</div>
			<div class="form-field">
				<div class="button button-large button-green" (click)="addPayment()">Добавить</div>
			</div>
		</app-form>
		<app-form class="admin-payments-forms-form" header="Удалить старую">
			<div class="form-field">
				<app-selector header="Платёжные системы" [items]="payments" [(ngModel)]="deletePaymentData"></app-selector>
			</div>
			<div class="form-field">
				<div class="button button-large button-red" (click)="deletePayment()">Удалить</div>
			</div>
		</app-form>
	</div>
</div>