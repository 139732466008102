import { Component, Input, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../../core/api/api.service';
import { AdminAccountsEditingInterface } from '../../shared/interfaces/admin-accounts-edit.interface';
import { NotifierService } from '../../../ui/notifier/notifier.service';
import { ModalityService } from '../../../modality/shared/services/modality.service';
import { AdminInterface } from '../../shared/interfaces/admin.interface';

@Component({
	selector: 'app-admin-accounts-edit',
	templateUrl: './admin-accounts-edit.component.html',
	styleUrls: ['./admin-accounts-edit.component.scss']
})
export class AdminAccountsEditComponent implements OnInit {

	@Input() private userId: number;
	
	public account: AdminAccountsEditingInterface;
	public admins: AdminInterface[];

	constructor(
		@Optional() @Inject(PLATFORM_ID) private platformId: Object,
		private apiService: ApiService,
		private notifierService: NotifierService,
		private modalityService: ModalityService
	) {}

	public form = {
		userId: '',
		channelName: '',
		chanellLink: '',
		admin: ('' as any)
	};

	ngOnInit() {
		
		if (isPlatformBrowser(this.platformId)) {

			this.init();
		}
	}

	private async init() {

		try {

			// Получаем данные аккаунта
			this.account = await this.apiService.call('account.getByUserId', {
				userId: this.userId,
				fields: ['id', 'channel_name', 'channel_link', 'admin']
			}).toPromise();

			// Запускаем функцию переназначения данных для формы
			this.initFormData();

			// Получаем список должностей администраторов
			this.admins = await this.apiService.call('admin.getRangs').toPromise();
		} catch(error) {

			this.close();
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	private initFormData() {

		this.form.userId = 'https://vk.com/' + this.account.vk.screen_name;
		this.form.channelName = this.account.channel_name;
		this.form.chanellLink = this.account.channel_link;
		this.form.admin = this.account.admin;
	}

	public async updateAccount() {

		try {

			await this.apiService.call('account.update', {
				user_id: this.userId,
				link_vk: this.form.userId,
				channel_name: this.form.channelName,
				channel_link: this.form.chanellLink,
				admin: this.form.admin.id
			}).toPromise();

			this.notifierService.update('Вы успешно обновили аккаунт', 'success', 5000);

			this.close();
		} catch(error) {
			
			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public async deleteAccount() {

		try {

			await this.apiService.call('account.delete', {
				id: this.account.id
			}).toPromise();

			this.notifierService.update('Аккаунт успешно удалён', 'success', 5000);

			this.close();
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	public close() {
		this.modalityService.destroyComponent();
	}
}
