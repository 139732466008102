import { Component, Input, HostListener } from '@angular/core';
import { AdminPayoutsInfoInterface } from '../../shared/interfaces/admin-payouts-info.interface';
import { AdminPayoutsInfoService } from '../../shared/services/admin-payouts-info.service';
import { StatusInterface } from '../../../core/interface/status.interface';
import { NotifierService } from '../../../ui/notifier/notifier.service';
import { ApiService } from '../../../core/api/api.service';

@Component({
	selector: 'app-admin-payouts-info-block',
	templateUrl: './admin-payouts-info.component.html',
	styleUrls: ['./admin-payouts-info.component.scss']
})
export class AdminPayoutsInfoBlockComponent {

	@Input() public item: AdminPayoutsInfoInterface;
	@Input() public statuses: StatusInterface[];

	constructor(
		public adminPayoutsInfoService: AdminPayoutsInfoService,
		private notifierService: NotifierService,
		private apiService: ApiService
	) {}

	public async chengeStatus(status: StatusInterface, payout: AdminPayoutsInfoInterface) {

		if (payout.status === status) {
			return false;
		}

		// Обновляем статус
		try {

			await this.apiService.call('payout.update.status', {
				id: payout.id,
				status: status.id
			}).toPromise();

			payout.status = status;
			this.notifierService.update('Статус обновлён', 'success', 5000);
		} catch(error) {

			console.error(error);
			this.notifierService.update(error.message, 'error', 5000);
		}
	}

	@HostListener('window:resize') onResize() {

		// Изменение окна
		this.adminPayoutsInfoService.translate();
	}
}
