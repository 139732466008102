import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable()
export class ScrollingService {
    
    constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platfotmId: Object
    ) {}

    private savedScrollPosition = -1;

    disableScroll() {
		if (isPlatformBrowser(this.platfotmId) && this.savedScrollPosition === -1) {
			this.savedScrollPosition = this.document.documentElement.scrollTop || this.document.body.scrollTop;
			this.document.body.style.position = 'fixed';
			this.document.body.style.overflow = 'hidden';
			this.document.body.style.top = '-' + this.savedScrollPosition + 'px';
		}
	}

	enableScroll() {
		if (isPlatformBrowser(this.platfotmId) && this.savedScrollPosition !== -1) {
			this.document.body.style.position = '';
			this.document.body.style.overflow = '';
			this.document.body.style.top = '';
			window.scrollTo(0, this.savedScrollPosition);
			this.savedScrollPosition = -1;
		}
	}

    top(n: number) {
		if (isPlatformBrowser(this.platfotmId)) {
            this.savedScrollPosition = -1;
			window.scrollTo(0, n);
		}
	}
}