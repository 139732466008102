<div class="profile-preview">
    <div class="fixed-size">
        <div class="profile-preview-wrapper">
            <div class="profile-preview-title">Личный кабинет пользователя</div>
        </div>
    </div>
</div>
<div class="profile-navigation">
    <div class="fixed-size">
        <div class="profile-navigation-wrapper">
            <a class="profile-navigation-item" [routerLink]="['/', 'profile']" [routerLinkActive]="'item-active'" [routerLinkActiveOptions]="{ exact: true }">Аккаунт</a>
            <a class="profile-navigation-item" [routerLink]="['/', 'profile', 'settings']" [routerLinkActive]="'item-active'" [routerLinkActiveOptions]="{ exact: true }">Настройки</a>
            <a class="profile-navigation-item" [routerLink]="['/', 'profile', 'payouts']" [routerLinkActive]="'item-active'" [routerLinkActiveOptions]="{ exact: true }">Все выплаты</a>
        </div>
    </div>
</div>
<div class="profile-content">
    <router-outlet></router-outlet>
</div>