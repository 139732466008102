import { Component } from '@angular/core';
import { AuthorizationService } from '../../../authorization/shared/services/authorization.service';

@Component({
	selector: 'app-admin-main-navigation',
	templateUrl: './admin-main-navigation.component.html',
	styleUrls: ['./admin-main-navigation.component.scss']
})
export class AdminMainNavigationComponent {

	constructor(
		public authorizationService: AuthorizationService
	) {}
}
