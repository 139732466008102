import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AdminPayoutsInfoService {

	private payoutsLength = 0;

	public translateX = 0;
	public activePayout = 0;

	constructor(
		@Inject(DOCUMENT) private document: Document
	) {}

	public init(payoutsLength: number) {

		// Инициализируем 

		this.payoutsLength = payoutsLength;
		this.translate();
	}

	public back() {

		// Если это не первый элемент
		if (this.activePayout > 0) {

			this.activePayout--;
			this.translate();
		}
	}

	public next() {

		// Если это не последний элемент элемент
		if (this.activePayout < (this.payoutsLength - 1)) {

			this.activePayout++;
			this.translate();
		}
	}

	public start() {

		this.activePayout = 0;
		this.translate();
	}

	public clear() {

		this.activePayout = 0;
		this.translate();
	}

	public translate() {

		// Ширина экрана
		const clientWidth = this.document.body.clientWidth;

		// Ширина, которую нам нужно отмотать
		let scrollWidth = 0;

		for (let i = 0; i < this.activePayout; i++) {

			// Добавляем ширину
			scrollWidth += this.document.getElementsByTagName('app-admin-payouts-info-block')[i]?.clientWidth || 750;
		}

		// Ширина элемента
		const elementWidth = this.document.getElementsByTagName('app-admin-payouts-info-block')[this.activePayout]?.clientWidth || 750;

		// Координата центра
		const centerWidth = (clientWidth - elementWidth) / 2 - scrollWidth;

		this.translateX = centerWidth;
	}
}